import {Container, Section} from "@webteam/layout";
import LogoFooter from "./img/jb-logo.svg";

function Footer() {
    return (
        <footer className="wt-offset-top-48">
            <div className="response">
                <div className="wt-row wt-row_size_m">
                    <div className="wt-col-auto-fill">
                        <a href="https://www.jetbrains.com">
                        <img src={LogoFooter} alt="Computational Arts Initiative" className="jb-logo" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
