import Button from "@webteam/button";

function Card({title, description, projectLink, articleLink, imagePath}) {
    return (
        <div className="wt-row">
            <div className="wt-col-6 wt-col-md-12 wt-col-lg-12 mask">
                <div className="card-image" style={{ backgroundImage: `url(${imagePath})` }}></div>
            </div>
            <div className="wt-col-6 wt-col-md-12 wt-col-lg-12">
                <div className="card-description">
                    <div className="wt-col-auto-fill">
                        <h3 className="wt-h3 wt-h3_theme_dark">{title}</h3>
                        <p className="wt-text-2 wt-text-2_theme_dark wt-offset-top-8">{description}</p>
                    </div>
                    <div className="wt-row wt-row_size_xs wt-offset-top-24">
                        <div className="wt-col-inline">
                            <Button href={projectLink} target="_blank" size="s" mode="outline" theme="dark">Open</Button>
                        </div>
                        <div className="wt-col-inline">
                            <Button href={articleLink} target="_blank" size="s" mode="nude" theme="dark">Read article</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;
