import { useGlowHover } from '@webteam/use-glow-hover';
const background = '#CACACD';


function Project({title, description, imageName, projectLink}) {
    const refCard = useGlowHover({
        hoverBg: '#6B57FF',
        lightColor: '#FF318C',
        lightSize: 250,
        isElementMovable: true
    });
    return (
        <div className="wt-col-3 wt-col-sm-12 wt-col-md-6 wt-col-lg-4 wt-col_align-self_stretch wt-offset-top-16">
            <a ref={refCard} href={projectLink} target="_blank" className="project">
                <div className="wt-card__section">
                    <div className={"icon " + imageName}></div>
                    <h3 className="wt-h3 wt-h3_theme_dark wt-offset-top-24">{title}</h3>
                    <p className="wt-text-2 wt-text-2_theme_dark wt-offset-top-8">{description}</p>
                </div>
            </a>
        </div>
    )
}

export default Project;
